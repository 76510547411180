export type PeriodTypeType = "upcoming" | "past";
export type PeriodLimitType = "1day" | "1week" | "30days" | "90days";
export type PaymentStatusType = "paid" | "onaccount";

export class FilterQuery {
  periodType: PeriodTypeType = "upcoming";
  periodLimit: PeriodLimitType = "1day";
  paymentStatus: PaymentStatusType | null = null;
  startDate: string | null = null;
  endDate: string | null = null;

  constructor(query?: Record<string, unknown>) {
    Object.assign(this, query);
  }
}

type VehicleCat = {
  id: number;
  name: string;
  image: string;
};

type Vehicle = {
  id: number;
  regNo: string;
  vehicleCat: VehicleCat;
};

export type Driver = {
  id: number;
  firstName: string;
  lastName?: string;
  fullName?: string;
  nameLetters?: string;
  telNo1?: string;
  positionLat?: number | null;
  positionLng?: number | null;
};

export type BookingVehicle = {
  driver: Driver | null;
  vehicle: Vehicle;
  driverConfirmed: null | boolean;
  isJobStarted: boolean;
  canJobStarted: boolean;
  canArrivedAtPickup: boolean;
  canPassengerOnBoard: boolean;
  canPassengerDropped: boolean;
  canJobComplete: boolean;
};

type Customer = {
  mobile: string | null;
  asString?: string;
  disableChargeWaitingTime: boolean | null;
};

export type BookingFile = {
  id: number;
  title?: string;
  clientFileName: string;
  downloadUrl: string;
};

export type Booking = {
  id: number;
  hash: string;
  paymentStatus:
    | "paid"
    | "onaccount"
    | "partially"
    | "paydriver"
    | "autopay"
    | null;
  jobStart: string;
  jobStartFormatted: string;
  meetgreetUrl: string;
  price?: number;
  priceFormatted?: number;
  pickup: string;
  destination: string;
  vehicles: BookingVehicle[];
  driverVehicle?: BookingVehicle;
  pickupInstructions: string | null;
  driverNotes?: string | null;
  flight?: string | null;
  clientRef1: string | null;
  hasCancelFee: boolean;
  isJobStarted: boolean;
  isCompleted: boolean;
  isCancelled: boolean;
  isVehicleLocationViewable: boolean;
  isPastJob: boolean;
  hasDriverConfirmedJob?: boolean;
  canCustomerCancelBooking: boolean;
  customerConfirmed: boolean;
  customer: Customer;
  clientContact: string | null;
  tags: string[] | null;
  pickupLat: number | null;
  pickupLng: number | null;
  destinationLat: number | null;
  destinationLng: number | null;
  driverFiles?: BookingFile[];
};

export type BookingGroups = {
  date: string;
  bookings: Booking[];
};

export type BookingPositionDetails = {
  id: number;
  pickupLat: number | null;
  pickupLng: number | null;
  destinationLat: number | null;
  destinationLng: number | null;
  driverPositionLat: number | null;
  driverPositionLng: number | null;
};

export type BookingExtraExpense = {
  id: number;
  quantity: number;
  description: string;
  isWaitingTimePaid?: boolean;
};
